import { isAfter } from 'date-fns/isAfter';
import { parse } from 'date-fns/parse';

import { defineStore } from '#src/stores/state-wrapper.js';

import {
  BUSINESS_OPTIONS,
  LTC_STATUS_OPTIONS,
  STATUS_OPTIONS,
} from '#src/structures/ExistingCoverage.js';

import { CoverageService } from '#src/services/coverage.service.js';
import { timestampFormatter, valOrNull, boolOrNull, generateUuid } from '#src/util/helpers.js';
import {
  savablePropertyRequestWrapper,
  useSavableProperty,
} from '#src/composables/savable-property.composable.js';

import {
  validateBoolean,
  validateIsBeforeNow,
  validateText,
} from '#src/composables/savable-property-validators.mjs';

import { useJointInsuredStore, usePrimaryInsuredStore } from '#src/stores/insured.js';

export const useExistingCoverageStore = (storeId, pinia, hot) =>
  defineStore(storeId, {
    state: () => ({
      storeId,
      id: null,
      insured_id: null,

      carrier_id: useSavableProperty({
        requestMap: 'carrier_id',
        rules: {
          minLength: {
            v: () => Boolean(useExistingCoverageStore(storeId, pinia).carrier_id.model),
            message: 'Must be a valid carrier',
          },
        },
      }),
      policy_number: useSavableProperty({
        requestMap: 'policy_number',
        rules: {
          minLength: validateText(
            () => useExistingCoverageStore(storeId, pinia).policy_number.model,
            {
              minLength: 1,
              maxLength: 255,
            },
          ),
        },
      }),
      face_amount: useSavableProperty({
        requestMap: 'face_amount',
        rules: {
          greaterThanZero: {
            v: () => useExistingCoverageStore(storeId, pinia).face_amount.model > 0,
            message: 'Must be greater than 0',
          },
        },
      }),
      status: useSavableProperty({
        requestMap: 'status',
        rules: {
          inList: {
            v: () => {
              const coverage = useExistingCoverageStore(storeId, pinia);
              const options = coverage.isLTC ? LTC_STATUS_OPTIONS : STATUS_OPTIONS;
              return options.includes(coverage.status.model);
            },
            message: 'Must be a valid status',
          },
        },
      }),
      policy_date: useSavableProperty({
        requestMap: 'policy_date',
        rules: {
          isBeforeToday: validateIsBeforeNow(
            () => useExistingCoverageStore(storeId, pinia).policy_date.model,
          ),
          isOnOrAfterInsuredsBirthdate: {
            v: () => {
              try {
                const coverage = useExistingCoverageStore(storeId, pinia);

                const birthday = parse(coverage.insuredBirthday, 'yyyy-MM-dd', new Date());
                return isAfter(
                  parse(coverage.policy_date.model, 'yyyy-MM-dd', new Date()),
                  birthday,
                );
              } catch (e) {
                return false;
              }
            },
            message: () => {
              const coverage = useExistingCoverageStore(storeId, pinia);
              const birthdateText = timestampFormatter(
                coverage.insuredBirthday,
                'sole-day',
                'basic',
              );

              return `Cannot be before ${coverage.insuredFirstName}'s date of birth (${birthdateText})`;
            },
          },
        },
      }),
      business_insurance: useSavableProperty({
        requestMap: 'business_insurance',
        rules: {
          inList: {
            v: () => {
              const coverage = useExistingCoverageStore(storeId, pinia);
              return BUSINESS_OPTIONS.some(
                ({ value }) => value === coverage.business_insurance.model,
              );
            },
            message: 'Must be in list',
          },
        },
      }),
      replacement_reason: useSavableProperty({
        requestMap: 'replacement_reason',
        rules: {
          minLength: validateText(
            () => useExistingCoverageStore(storeId, pinia).replacement_reason.model,
            { minLength: 1, maxLength: 255 },
          ),
        },
      }),
      monthly_benefit: useSavableProperty({
        requestMap: 'monthly_benefit',
        rules: {
          greaterThanZero: {
            v: () => useExistingCoverageStore(storeId, pinia).monthly_benefit.model > 0,
            message: 'Must be greater than 0',
          },
        },
      }),
      premium: useSavableProperty({
        requestMap: 'premium',
        rules: {
          greaterThanZero: {
            v: () => useExistingCoverageStore(storeId, pinia).premium.model > 0,
            message: 'Must be greater than 0',
          },
        },
      }),
      sold_by_same_agent: useSavableProperty({
        requestMap: 'sold_by_same_agent',
        rules: {
          isTrueOrFalse: validateBoolean(
            () => useExistingCoverageStore(storeId, pinia).sold_by_same_agent.model,
          ),
        },
      }),
      other_carrier_name: useSavableProperty({
        requestMap: 'other_carrier_name',
        rules: {
          minLength: validateText(
            () => useExistingCoverageStore(storeId, pinia).other_carrier_name.model,
            { minLength: 1, maxLength: 255 },
          ),
        },
      }),
    }),
    getters: {
      insuredBirthday() {
        const primary = usePrimaryInsuredStore(pinia);
        if (primary.id === this.insured_id) return primary.birthdate.model;

        const joint = useJointInsuredStore(pinia);
        return joint.birthdate.model;
      },
      insuredFirstName() {
        const primary = usePrimaryInsuredStore(pinia);
        if (primary.id === this.insured_id) return primary.first_name.model;

        const joint = useJointInsuredStore(pinia);
        return joint.first_name.model;
      },
    },
    actions: {
      initializeFromEapp(rawCoverage) {
        this.id = valOrNull(rawCoverage?.id);
        this.insured_id = valOrNull(rawCoverage?.insured_id);

        this.carrier_id.load(valOrNull(rawCoverage?.carrier?.id));
        this.policy_number.load(valOrNull(rawCoverage?.policy_number));
        this.face_amount.load(valOrNull(rawCoverage?.face_amount));
        this.status.load(valOrNull(rawCoverage?.status));
        this.policy_date.load(valOrNull(rawCoverage?.policy_date));
        this.business_insurance.load(boolOrNull(rawCoverage?.business_insurance));
        this.replacement_reason.load(valOrNull(rawCoverage?.replacement_reason));
        this.monthly_benefit.load(valOrNull(rawCoverage?.monthly_benefit));
        this.premium.load(valOrNull(rawCoverage?.premium));
        this.sold_by_same_agent.load(boolOrNull(rawCoverage?.sold_by_same_agent));
        this.other_carrier_name.load(valOrNull(rawCoverage?.other_carrier_name));
      },
      async savablePropertyWrapper(func, { attributes, body, uuid }) {
        const composableAttributes = attributes.map((a) => this[a]);
        return savablePropertyRequestWrapper(func, { composableAttributes, body, uuid });
      },
      async saveAttributes(attributes = []) {
        if (!attributes.length) return;
        const coverageService = new CoverageService(pinia);

        const body = {};
        attributes.forEach((a) => {
          const key = this[a].requestMap
          const value = this[a].format();
          body[key] = value;
        });

        return this.savablePropertyWrapper(() => coverageService.updateCoverage(this.id, body), {
          attributes,
          body,
          uuid: generateUuid(),
        });
      },
    },
  })(pinia, hot);
