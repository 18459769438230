import {
  FormAlert,
  FormSubheader,
  focusOnFirstEmptyElement,
  validateFormStructure,
} from '#src/components/form-builder/FormBuilderTemplates.js';

import { format } from 'date-fns/format';
import { storeToRefs } from 'pinia';

import { useInsuredStore } from '#src/stores/insured.js';
import {
  useInsuredEmailProperty,
  useInsuredFirstNameProperty,
  useInsuredLastNameProperty,
  useInsuredPhoneMobileProperty,
  useInsuredResendClientIntroMessageProperty,
  useInsuredSendClientIntroMessageProperty,
} from '#src/composables/insured-form-property.composable.js';
import { computed } from 'vue';
import { mdiAccount } from '@mdi/js';
import { useStepContactStore } from '#src/stores/step-contact.js';

export const useContactForm = (pinia, module, { dense } = {}) => {
  const insured = useInsuredStore(module, pinia);

  const contactStep = useStepContactStore(pinia);

  const {
    phone_mobile,
    email,
    first_name,
    last_name,
    intro_last_sent_at,
    send_client_intro_message,
  } = storeToRefs(insured);

  const cannotSendLink = computed(() => {
    return (
      !phone_mobile.value.success ||
      !email.value.success ||
      !first_name.value.success ||
      !last_name.value.success
    );
  });

  async function sendLink(setNullIfFail = false) {
    try {
      await insured.saveAttributes(['send_client_intro_message']);
    } catch (error) {
      if (setNullIfFail) send_client_intro_message.value.load(null);
    }
  }

  // form fields
  function formTitle() {
    return FormSubheader(
      computed(() => `What is ${first_name.value.model}'s contact information?`),
      'override-title',
    );
  }

  function firstNameFormInput() {
    return useInsuredFirstNameProperty(pinia, module, {
      prependInnerIcon: mdiAccount,
      mdColSize: dense ? 6 : 3,
    });
  }

  function lastNameFormInput() {
    return useInsuredLastNameProperty(pinia, module, {
      prependInnerIcon: mdiAccount,
      mdColSize: dense ? 6 : 3,
    });
  }

  function emailFormInput() {
    return useInsuredEmailProperty(pinia, module, { mdColSize: dense ? 6 : 3 });
  }

  function phoneFormInput() {
    return useInsuredPhoneMobileProperty(pinia, module, { mdColSize: dense ? 6 : 3 });
  }

  function linkWasSentAlert() {
    let date = intro_last_sent_at.value;
    if (!date) return null;
    if (typeof intro_last_sent_at.value === 'string') {
      date = new Date(intro_last_sent_at.value);
    }
    const lastSentAt = format(date, 'MMM dd, yyyy');
    return FormAlert(
      `A link to this application was sent to ${first_name.value.model} on ${lastSentAt}. Click Next to continue.`,
      'sent-notification',
      {
        type: 'success',
        variant: 'outlined',
        border: 'start',
        class: 'text-left mb-3',
        mdColSize: 12,
      },
    );
  }

  function linkWasNotSentAlert() {
    return FormAlert(
      `A link to this application will not be sent to ${first_name.value.model}. Click Next to continue.`,
      'sent-notification',
      {
        type: 'info',
        variant: 'outlined',
        border: 'start',
        class: 'text-left mb-3',
      },
    );
  }

  function resendLinkFormInput() {
    return useInsuredResendClientIntroMessageProperty(pinia, module, {
      label: `Resend ${first_name.value.model} a link to this application?`,
      disabled: cannotSendLink,
      overrideSave: sendLink,
    });
  }

  function sendLinkFormInput() {
    return useInsuredSendClientIntroMessageProperty(pinia, module, {
      label: `Send ${first_name.value.model} a link to this application?`,
      disabled: cannotSendLink,
      overrideSave: () => sendLink(true),
      mdColSize: 12,
    });
  }

  // structure & watchers
  function buildContactData() {
    return {
      fields: [
        formTitle(),
        firstNameFormInput(),
        lastNameFormInput(),
        emailFormInput(),
        phoneFormInput(),
      ],
      key: `${module}-contact-root`,
    };
  }

  function buildInviteData() {
    const fields = [];
    if (send_client_intro_message.value.model && intro_last_sent_at.value) {
      fields.push(resendLinkFormInput(), linkWasSentAlert());
    } else {
      fields.push(sendLinkFormInput());
      if (send_client_intro_message.value.model === false) {
        fields.push(linkWasNotSentAlert());
      }
    }
    return { fields, key: 'invite' };
  }

  function buildStructure({ skipFocus = false } = {}) {
    const forms = [buildContactData()];
    if (contactStep.requiredFields.sendClientIntroMessage) forms.push(buildInviteData());
    if (!skipFocus) focusOnFirstEmptyElement(forms);
    return forms;
  }

  const reactiveDependencies = [
    computed(() => intro_last_sent_at.value),
    computed(() => send_client_intro_message.value.model),
  ];

  function validate(throwOnFailure = false) {
    return validateFormStructure(buildStructure({ skipFocus: true }), throwOnFailure);
  }

  return { validate, buildStructure, reactiveDependencies };
};
