import { httpClient } from '#src/HttpClient.js';
import { useEappStore } from '#src/stores/electronic-application.js';
import { useInstanceSettingsStore } from '#src/stores/instance-settings.js';

export class SubmissionsService {
  #pinia;
  #baseUrl;
  #httpClient;

  constructor(pinia) {
    this.#pinia = pinia;
    const instance = useInstanceSettingsStore(pinia);
    this.#baseUrl = instance.api_url;
    this.#httpClient = httpClient(pinia);
  }

  static METHOD = {
    SUBMIT: 'SUBMIT',
    CARRIER_URL: 'CARRIER_URL',
    AGENT_OR_INSURED: 'AGENT_OR_INSURED',
    DOCUSIGN: 'DOCUSIGN',
    PAUSED_FOR_CONTRACTING: 'PAUSED_FOR_CONTRACTING',
    WAITING_FOR_CARRIER_RESPONSE: 'WAITING_FOR_CARRIER_RESPONSE',
    AGENT_ONLY: 'AGENT_ONLY',
  };

  async getSubmissionMethod() {
    const eApp = useEappStore(this.#pinia);
    try {
      const { data } = await this.#httpClient.post(
        `${this.#baseUrl}/strife/v1/electronic_applications/${eApp.id}/contracting`,
        {
          electronic_application_id: eApp.id,
          uuid: eApp.uuid,
        },
      );

      let submission_method = SubmissionsService.METHOD.PAUSED_FOR_CONTRACTING;
      if (Object.values(SubmissionsService.METHOD).includes(data.submission_method)) {
        submission_method = data.submission_method;
      }
      return {
        submission_method,
        carrier_eapp_url: data.carrier_eapp_url,
      };
    } catch (e) {
      throw {
        message: e.response?.data?.message,
        carrier_eapp_errors: e.response?.data?.carrier_eapp_errors,
      };
    }
  }

  submitEapp() {
    const eApp = useEappStore(this.#pinia);
    return this.#httpClient.post(
      `${this.#baseUrl}/strife/v1/electronic_applications/${eApp.id}/submit`,
      {
        electronic_application_id: eApp.id,
        uuid: eApp.uuid,
      },
    );
  }
}
