<template>
  <v-col class="pa-0 themed-widget-layout" data-testid="themed-widget-layout">
    <v-app-bar
      v-show="props.showHeader"
      id="step-header"
      color="transparent"
      elevation="0"
      class="unset-height-app-bar"
    >
      <v-col class="pa-0">
        <slot name="alert" />
        <slot name="header" />
      </v-col>
    </v-app-bar>
    <v-row
      id="step-container"
      class="ma-0 flex h-100"
      align="center"
      justify="center"
      :class="{
        'no-line-height': stepIsFullScreen,
      }"
      :style="{ minHeight: stepIsFullScreen ? '530px' : '400px' }"
      :data-testid="`step-${activeRouteName}`"
    >
      <v-col align="center" justify="center" cols="12" class="w-100" :class="innerClasses">
        <slot name="body" />
      </v-col>
    </v-row>

    <v-footer
      v-show="props.showFooter"
      id="step-footer"
      ref="footer"
      :elevation="0"
      color="transparent"
      align-items="center"
    >
      <slot name="footer" />
    </v-footer>

    <div v-if="$slots.chat" id="themed-widget-chat-parent">
      <div id="themed-widget-chat">
        <div id="chat">
          <slot name="chat" />
        </div>
      </div>
    </div>
  </v-col>
</template>

<script setup>
import { storeToRefs } from 'pinia';
import { useFlowStore } from '#src/stores/flow';
import { useDisplay } from 'vuetify';
import { APPLY_ROUTE_META_NAMES } from '#src/enumerations/step-enumeration';
import { computed, inject } from 'vue';

const props = defineProps({
  showFooter: Boolean,
  showHeader: Boolean,
});

const { smAndDown } = useDisplay();

const pinia = inject('pinia');
const flow = useFlowStore(pinia);
const { activeStep } = storeToRefs(flow);

const stepIsFullScreen = computed(() => activeStep.value?.isFullscreen);
const activeRouteName = computed(() =>
  activeStep.value ? APPLY_ROUTE_META_NAMES[activeStep.value.step] : null,
);
const innerClasses = computed(() => {
  if (stepIsFullScreen.value) return 'pa-0 align-self-stretch flex';
  else if (smAndDown.value) return 'pa-1';
  return '';
});
</script>

<style lang="scss">
.themed-widget-layout {
  #step-header {
    z-index: 10 !important;
    position: relative !important;
  }

  #step-footer {
    width: 100% !important;
    position: relative !important;
  }

  #step-container {
    scroll-behavior: smooth;
  }

  #themed-widget-chat-parent {
    #themed-widget-chat {
      transform-origin: bottom right;
      position: fixed;
      right: 20px;
      bottom: 20px;
      z-index: 21;
    }
  }
}
</style>
