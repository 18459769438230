import { steps, stepToRouteName } from '#src/enumerations/step-enumeration.js';

import { defineStore } from '#src/stores/state-wrapper.js';
import { PRODUCTS } from '#src/structures/ProductType.js';

import { useStepBuildStore } from '#src/stores/step-build.js';
import { useInstanceSettingsStore } from '#src/stores/instance-settings.js';
import { useFlowStore } from '#src/stores/flow.js';
import { useStepQuoteStore } from '#src/stores/step-quote.js';
import { useStepBenefitEstimationStore } from '#src/stores/step-benefit-estimation.js';
import { useStepPreQuoteInformationStore } from '#src/stores/step-pre-quote-information.js';
import { useStepLifePriorityStore } from '#src/stores/step-life-priority.js';
import { useStepCompareStore } from '#src/stores/step-compare.js';
import { useBirthdateForm } from '#src/components/steps/birthdate/birthdate-form.composable.js';

export const useStepBirthdateStore = (pinia, hot) =>
  defineStore('step-birthdate', {
    getters: {
      step: () => steps.BIRTHDATE,
      routeName: (s) => stepToRouteName[s.step],
      previous: () => {
        const build = useStepBuildStore(pinia);
        if (build.inactive) return build.previous;
        return build.step;
      },
      next() {
        const instanceSettingsStore = useInstanceSettingsStore(pinia);
        const availablePriorities = instanceSettingsStore.availablePriorities;

        const estProducts = [PRODUCTS.WHOLE_LIFE, PRODUCTS.ACCUMULATION_IUL];

        const benefitEstEnabled = estProducts.some((v) => instanceSettingsStore.products[v]);
        const incomeRiderEnabled = instanceSettingsStore.products[PRODUCTS.INCOME_RIDER];

        const flow = useFlowStore(pinia);
        const quote = useStepQuoteStore(pinia);
        if (flow.onlyAccidentalDeath || flow.onlyFinalExpense) {
          return quote.step;
        }

        if (
          instanceSettingsStore.available.length === 2 &&
          instanceSettingsStore.products[PRODUCTS.ACCIDENTAL_DEATH] &&
          instanceSettingsStore.products[PRODUCTS.FINAL_EXPENSE]
        ) {
          return quote.step;
        }

        const benefitEstimation = useStepBenefitEstimationStore(pinia);
        if (instanceSettingsStore.available.length === 1 && benefitEstEnabled) {
          return benefitEstimation.step;
        }

        const preQuoteInformation = useStepPreQuoteInformationStore(pinia);
        if (instanceSettingsStore.available.length === 1 && incomeRiderEnabled) {
          return preQuoteInformation.step;
        }

        const lifePriority = useStepLifePriorityStore(pinia);
        if (availablePriorities.length > 1) return lifePriority.step;

        if (flow.onlyOnePermanentProduct) return quote.step;

        const compare = useStepCompareStore(pinia);
        return compare.step;
      },
    },
    actions: {
      generateForm(module, args) {
        return useBirthdateForm(pinia, module, args);
      },
    },
  })(pinia, hot);
