import { defineStore } from '#src/stores/state-wrapper.js';
import { steps, stepToRouteName } from '#src/enumerations/step-enumeration.js';
import { REQS } from '#src/enumerations/data-requirements.js';

import { useStepUnderwritingStore } from '#src/stores/step-underwriting.js';
import { useStepExamSchedulingStore } from '#src/stores/step-exam-scheduling.js';
import { useStepOwnerStore } from '#src/stores/step-owner.js';
import { getPartyStore, useEappStore } from '#src/stores/electronic-application.js';
import {
  INSURED_TYPES,
  useInsuredStore,
  usePrimaryInsuredStore,
  useJointInsuredStore,
} from '#src/stores/insured.js';

export const useStepPaymentStore = (pinia, hot) =>
  defineStore('step-payment', {
    state: () => ({
      inactive: false,
      requiredFields: {
        payment: false,
        payorName: false,
        payorAddress: false,
        payorEmail: false,
        payorPhone: false,
        payorSsn: false,
        payorTin: false,
        payorBirthdate: false,
        payorFormationDate: false,
        payorRelationship: false,
        eftDraftDayOfMonth: false,
      },
    }),
    getters: {
      step: () => steps.PAYMENT,
      canSelectPayor: (s) => Object.values(s.requiredFields).includes(true),
      routeName: (s) => stepToRouteName[s.step],
      previous() {
        const underwriting = useStepUnderwritingStore(pinia);
        return underwriting.step;
      },
      next() {
        const examScheduling = useStepExamSchedulingStore(pinia);
        return examScheduling.step;
      },
    },
    actions: {
      setStepRequirements(srq) {
        this.requiredFields.payment = srq[REQS.PAYMENT]
        this.requiredFields.payorName = srq[REQS.PAYOR_NAME];
        this.requiredFields.payorAddress = srq[REQS.PAYOR_ADDRESS];
        this.requiredFields.payorEmail = srq[REQS.PAYOR_EMAIL];
        this.requiredFields.payorPhone = srq[REQS.PAYOR_PHONE];
        this.requiredFields.payorSsn = srq[REQS.PAYOR_PARTY_ID];
        this.requiredFields.payorTin = srq[REQS.PAYOR_PARTY_ID];
        this.requiredFields.payorBirthdate = srq[REQS.PAYOR_BIRTHDATE];
        this.requiredFields.payorFormationDate = srq[REQS.PAYOR_FORMATION_DATE];
        this.requiredFields.payorRelationship = srq[REQS.PAYOR_RELATIONSHIP];
        this.requiredFields.eftDraftDayOfMonth = srq[REQS.PAYOR_DRAFT_DAY_OF_MONTH];

        this.inactive = !Object.values(this.requiredFields).includes(true);
      },
      async beforeLoadAction() {
        await this.reconcilePayorRoles();
        return true;
      },
      async reconcilePayorRoles() {
        const eApp = useEappStore(pinia);
        const ownerStep = useStepOwnerStore(pinia);

        if (eApp.isDualInsured && !eApp.isMultiplePolicies)
          await this.reconcileDualInsuredSinglePolicy();

        if (this.canSelectPayor) return this.defaultPayorRoleToOwner();

        if (ownerStep.inactive) return this.reconcileInactiveOwnerStepPayors();
        return this.reconcileActiveOwnerStepPayors();
      },
      // Single Policy Dual Insured Payor Role Reconciliation
      async reconcileDualInsuredSinglePolicy() {
        // Only one policy = only insured's payor = delete joint payor
        const joint = useJointInsuredStore(pinia);

        if (!joint.payorKey) return;
        await joint.deletePayor();
      },

      // Inactive Owner Step Payor Role Reconciliation
      async makePrimaryInsuredPayor() {
        const insured = usePrimaryInsuredStore(pinia);

        if (insured.payorKey && insured.payorKey !== INSURED_TYPES.INSURED) {
          await insured.deletePayor();
        }
        if (!insured.payorKey) await insured.addPayorRole({ insured_id: insured.id });
      },
      async deleteJointNonInsuredPayors() {
        const joint = useJointInsuredStore(pinia);

        const jointPayorIsAnInsured = [INSURED_TYPES.INSURED, INSURED_TYPES.JOINT].includes(
          joint.payorKey,
        );

        if (!joint.payorKey || jointPayorIsAnInsured) return;
        await joint.deletePayor();
      },
      async reconcileInactiveOwnerStepPayors() {
        const eApp = useEappStore(pinia);
        // Inactive Owner Step = insured is payor = Delete non-insured payors, add insured payor role
        await this.makePrimaryInsuredPayor();

        // Inactive Owner Step + multiple policies = insured or joint insured is payor = Delete non-insured payors
        if (eApp.isMultiplePolicies) await this.deleteJointNonInsuredPayors();
      },

      // Active Owner Step Payor Role Reconciliation
      async makeOwnerPayorForInsured(insuredType) {
        const insured = useInsuredStore(insuredType, pinia);
        const eApp = useEappStore(pinia);

        if (insured.payorKey && insured.payorKey === eApp.ownerKey) return;
        if (insured.payorKey) await insured.deletePayor();
        const owner = getPartyStore(eApp.ownerKey, pinia);
        await owner.addPayorRole({ insured_id: insured.id });
      },
      async reconcileActiveOwnerStepPayors() {
        const eApp = useEappStore(pinia);
        // Active Owner Step = owner is payor = Delete non-owner payors, add owner payor role
        await this.makeOwnerPayorForInsured(INSURED_TYPES.INSURED);
        if (eApp.isMultiplePolicies) await this.makeOwnerPayorForInsured(INSURED_TYPES.JOINT);
      },
      async defaultPayorRoleToOwner() {
        const insured = usePrimaryInsuredStore(pinia);
        const eApp = useEappStore(pinia);

        if (insured.payorKey) return;
        const owner = getPartyStore(eApp.ownerKey, pinia);
        await owner.addPayorRole({ insured_id: insured.id });
      },
    },
  })(pinia, hot);
