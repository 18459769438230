import { boolOrNull, isBoolean, numOrNull, valOrNull } from '#src/util/helpers.js';
import { categoryToConstantProduct } from '#src/structures/ProductType.js';

// Used for API responses
export function PublicQuoteDTO(quote) {
  return {
    benefit_period: quote.benefit_period || null,
    carrier: PublicCarrierDTO(quote.carrier),
    chronic_illness_rider: boolOrNull(quote.ltc_rider),
    death_benefit: quote.death_benefit || quote.face_amount || quote.initial_death_benefit || null,
    discount: quote.discount || null,
    distributions_yearly_level: quote.distributions_yearly_level || null,
    distributions_cumulative: quote.distributions_cumulative || null,
    elimination_period: quote.elimination_period || null,
    exam: PublicExamDetailsDTO({
      required: quote.exam_required,
      details: quote.exam_details?.details,
    }),
    expires_at: quote.expires_at || null,
    guaranteed_account_values: quote.guaranteed_account_values || null,
    guaranteed_issue: boolOrNull(quote.guaranteed_issue),
    advisor_guide: boolOrNull(quote.advisor_guide),
    consumer_guide: boolOrNull(quote.consumer_guide),
    underwriting_guide: boolOrNull(quote.underwriting_guide),
    home_health_care_waiver: boolOrNull(quote.home_health_care_waiver),
    id: quote.id,
    illustration_available: quote.illustration_available || null,
    inflation_percentage: quote.inflation_percentage || null,
    instant_decision: Boolean(quote.instant_decision),
    joint_id: quote.joint_insured_quote_id || null,
    joint_waiver_of_premium: boolOrNull(quote.joint_waiver_of_premium),
    lapse_protection_to_age: quote.lapse_protection_to_age || null,
    living_benefits: PublicLivingBenefitsDetailsDTO({
      details: quote.living_benefits_details?.details,
      enabled: boolOrNull(quote.chronic_illness_rider),
    }),
    ltc_pool_of_money_values: quote.ltc_pool_of_money_values || null,
    ltc_rider: boolOrNull(quote.ltc_rider),
    ltc_rider_percentage: numOrNull(quote.ltc_rider_percentage),
    mode: quote.mode || null,
    monthly_benefit: quote.monthly_benefit || null,
    monthly_benefit_values: quote.monthly_benefit_values || null,
    non_guaranteed_account_values: quote.non_guaranteed_account_values || null,
    non_guaranteed_distributions: quote.non_guaranteed_distributions || null,
    partner_discount: quote.partner_discount || null,
    pay_duration: quote.pay_duration || null,
    pool_of_money: quote.pool_of_money || null,
    preferred: boolOrNull(quote.preferred),
    premium: quote.premium || null,
    premium_values: quote.premium_values || null,
    product: PublicProductDTO(quote.product),
    rating: PublicValidatedBuildDTO({
      min_weight: quote.validated_build?.min_weight,
      max_weight: quote.validated_build?.max_weight,
      error: quote.validated_rating_error,
      rating: quote.validated_rating,
    }),
    return_of_premium_rider: quote.return_of_premium_rider || null,
    save_age: PublicSaveAgeDTO({
      save_age_used: quote.save_age_used,
      save_age_suggestion: quote.save_age_suggestion,
      details: quote.save_age_text,
    }),
    selected_type: quote.selected_type || null,
    shared_care: boolOrNull(quote.shared_care),
    state: quote.state || null,
    term_duration: quote.term_duration || null,
    vitality_level: quote.vitality_level || null,
  };
}

function PublicSaveAgeDTO({ save_age_suggestion, details, save_age_used }) {
  return {
    details: details || null,
    enabled: boolOrNull(save_age_used),
    suggested: boolOrNull(save_age_suggestion),
  };
}

function PublicProductDTO(product) {
  let eDelivery = product.carrier_e_delivery;
  if (!isBoolean(product.carrier_e_delivery)) eDelivery = null;
  return {
    conversion: valOrNull(product.conversion),
    disclosure: valOrNull(product.product_details),
    e_delivery: eDelivery,
    name: valOrNull(product.name),
    require_payment: boolOrNull(product.require_payment),
    types: categoryToConstantProduct(product.category),
  };
}

function PublicCarrierDTO(carrier) {
  return {
    am_best_rating: valOrNull(carrier.am_best_rating),
    approval_time: valOrNull(carrier.approval_time) || 0,
    avatar_url: valOrNull(carrier.avatar_url),
    established_year: numOrNull(carrier.established_year),
    name: valOrNull(carrier.name),
  };
}

function PublicValidatedBuildDTO({ error, max_weight, min_weight, rating }) {
  return {
    error: error || null,
    max_weight: max_weight || null,
    min_weight: min_weight || null,
    rating: rating || null,
  };
}

function PublicLivingBenefitsDetailsDTO({ details, enabled }) {
  return {
    details: details || null,
    enabled: Boolean(enabled),
  };
}

function PublicExamDetailsDTO(examDetails) {
  let required = 'exam_not_required';
  if (examDetails?.required === true) required = 'exam_is_required';
  else if (examDetails?.required === 'possibly') required = 'exam_possibly_required';

  return {
    details: examDetails?.details || null,
    required,
  };
}

export function InternalQuote(model) {
  return {
    age_value: model?.age_value,
    illustration_id: model?.illustration_id,
    benefit_period: model?.benefit_period,
    birthdate: model?.birthdate,
    carrier: {
      am_best_rating: model?.carrier?.am_best_rating,
      approval_time: model?.carrier?.approval_time,
      avatar_url: model?.carrier?.avatar_url,
      name: model?.carrier?.name,
      strife_disclosure: model?.carrier?.strife_disclosure,
      signature_age: model?.carrier?.signature_age,
      established_year: model?.carrier?.established_year,
    },
    cash_benefit_percentage: model?.cash_benefit_percentage,
    chronic_illness_rider: model?.chronic_illness_rider,
    death_benefit: model?.death_benefit,
    digital_application_text: {
      description: model?.digital_application_text?.description,
      title: model?.digital_application_text?.title,
    },
    discount_text: model?.discount_text,
    distributions_cumulative: model?.distributions_cumulative,
    distributions_yearly_level: model?.distributions_yearly_level,
    elimination_period_text: model?.elimination_period_text,
    elimination_period: model?.elimination_period,
    enhanced_income_amount_text: {
      title: model?.enhanced_income_amount_text?.title,
      description: model?.enhanced_income_amount_text?.description,
    },
    exam_details: {
      details: model?.exam_details?.details,
    },
    exam_required: model?.exam_required,
    expires_at: model?.expires_at,
    face_amount: model?.face_amount,
    fee_applies_to: model?.fee_applies_to,
    fee_rate: model?.fee_rate,
    featured_rate_link: model?.featured_rate_link,
    filter_chronic_illness_and_ltc: model?.filter_chronic_illness_and_ltc,
    free_withdrawals_text: {
      title: model?.free_withdrawals_text?.title,
      description: model?.free_withdrawals_text?.description,
    },
    graded_death_benefit: model?.graded_death_benefit,
    guaranteed_issue: model?.guaranteed_issue,
    home_health_care_waiver: model?.home_health_care_waiver,
    id: model?.id,
    real_id: model?.real_id,
    real_joint_id: model?.real_joint_id,
    illustration_available: model?.illustration_available,
    income_amount: model?.income_amount,
    income_end_age: model?.income_end_age,
    income_solve: model?.income_solve,
    income_start_age: model?.income_start_age,
    inflation_percentage: model?.inflation_percentage,
    initial_death_benefit: model?.initial_death_benefit,
    joint: model?.joint,
    joint_income: model?.joint_income,
    joint_insured_premium: model?.joint_insured_premium,
    joint_insured_quote_id: model?.joint_insured_quote_id,
    joint_insured_validated_build: {
      max_weight: model?.joint_insured_validated_build?.max_weight,
    },
    joint_insured_validated_rating_error: model?.joint_insured_validated_rating_error,
    joint_insured_validated_rating: model?.joint_insured_validated_rating,
    joint_insured_validated_smoking_types: model?.joint_insured_validated_smoking_types,
    joint_waiver_of_premium: model?.joint_waiver_of_premium,
    joint_waiver_of_premium_text: model?.joint_waiver_of_premium_text,
    lapse_protection_to_age: model?.lapse_protection_to_age,
    living_benefits_details: {
      details: model?.living_benefits_details?.details,
      pdf: model?.living_benefits_details?.pdf,
    },
    loader: model?.loader,
    ltc_amount: model?.ltc_amount,
    ltc_rider_percentage: model?.ltc_rider_percentage,
    ltc_rider: model?.ltc_rider,
    ltc_pool_of_money_values: model?.ltc_pool_of_money_values || [],
    monthly_benefit_values: model?.monthly_benefit_values || [],
    max_ltc_amount: model?.max_ltc_amount,
    mec_year: model?.mec_year,
    mode: model?.mode,
    monthly_benefit: model?.monthly_benefit,
    non_guaranteed_distributions: model?.non_guaranteed_distributions,
    non_guaranteed_account_values: model?.non_guaranteed_account_values,
    non_guaranteed_lapse_protection_to_age: model?.non_guaranteed_lapse_protection_to_age,
    one_partner_discount_percentage: model?.one_partner_discount_percentage,
    partner_discount_percentage: model?.partner_discount_percentage,
    partner_discount_text: model?.partner_discount_text,
    partner_discount: model?.partner_discount,
    pay_duration: model?.pay_duration,
    pool_of_money: model?.pool_of_money,
    preferred_carrier: model?.preferred_carrier,
    preferred: model?.preferred,
    premium: model?.premium,
    premium_values: model?.premium_values,
    product: {
      active_years: model?.product?.active_years,
      advisor_use_only: model?.product?.advisor_use_only,
      carrier_e_delivery: model?.product?.carrier_e_delivery,
      category: model?.product?.category,
      conversion: model?.product?.conversion,
      has_advisor_guide: model?.product?.has_advisor_guide,
      has_consumer_guide: model?.product?.has_consumer_guide,
      has_underwriting_guide: model?.product?.has_underwriting_guide,
      id: model?.product?.id,
      instant_aps: model?.product?.instant_aps,
      multiple_policies: model?.product?.multiple_policies,
      name: model?.product?.name,
      payment_required: model?.product?.payment_required,
      product_details: model?.product?.product_details,
      require_payment: model?.product?.require_payment,
      riders: {
        chronic_illness: model?.riders?.chronic_illness,
        critical_illness_rider: model?.riders?.critical_illness_rider,
      },
      tax_free_income_pdf_url: model?.tax_free_income_pdf_url,
      vitality_rider: model?.vitality_rider,
    },
    rank: model?.rank,
    rate: model?.rate,
    section_1035_exchange_amount: model?.section_1035_exchange?.amount,
    selected_type: model?.selected_type,
    save_age_text: model?.save_age_text,
    save_age_suggestion: model?.save_age_suggestion,
    save_age_used: model?.save_age_used,
    shared_care_text: model?.shared_care_text,
    shared_care: model?.shared_care,
    single_or_joint_income_text: {
      title: model?.single_or_joint_income_text?.title,
      description: model?.single_or_joint_income_text?.description,
    },
    solve: model?.solve,
    state: model?.state,
    surrender_charge_text: {
      title: model?.surrender_charge_text?.title,
      description: model?.surrender_charge_text?.description,
    },
    term_duration: model?.term_duration,
    type: model?.type,
    validated_build: {
      max_weight: model?.validated_build?.max_weight,
    },
    validated_rating_error: model?.validated_rating_error,
    validated_rating: model?.validated_rating,
    vitality_level: model?.vitality_level,
    waiver_of_premium_text: model?.waiver_of_premium_text,
    years_deferred: model?.years_deferred,
  };
}
