import { REQS } from '#src/enumerations/data-requirements.js';
import { steps, stepToRouteName } from '#src/enumerations/step-enumeration.js';
import { QuestionsService } from '#src/services/questions.service.js';
import { defineStore } from '#src/stores/state-wrapper.js';

import {
  INSURED_TYPES,
  useInsuredStore,
  useJointInsuredStore,
  usePrimaryInsuredStore,
} from '#src/stores/insured.js';
import { useStepSecondaryAddresseeStore } from '#src/stores/step-secondary-addressee.js';
import { useStepPaymentStore } from '#src/stores/step-payment.js';
import { useUnderwritingQuestion } from '#src/stores/underwriting-question.js';
import { HttpIndividualsService } from '#src/services/http-individuals.service.js';

export function useStepUnderwritingStore(pinia, hot) {
  const individualService = new HttpIndividualsService(pinia);
  const questionsService = new QuestionsService(pinia);
  return defineStore('step-underwriting', {
    state: () => ({
      requiredFields: {
        build: false,
      },
      insuredBuild: {
        valid: null,
        exists: null,
        minWeight: null,
        maxWeight: null,
        rating: null,
        okayToProceed: null,
      },
      jointInsuredBuild: {
        valid: null,
        exists: null,
        minWeight: null,
        maxWeight: null,
        rating: null,
        okayToProceed: null,
      },
      insuredRootQuestions: [],
      insuredChildQuestions: {},
      jointRootQuestions: [],
      jointChildQuestions: {},
      sharedRootQuestions: [],
    }),
    getters: {
      step: () => steps.UNDERWRITING,
      routeName: (s) => stepToRouteName[s.step],
      previous() {
        const secondary = useStepSecondaryAddresseeStore(pinia);
        if (secondary.inactive) return secondary.previous;
        return secondary.step;
      },
      next() {
        const payment = useStepPaymentStore(pinia);
        if(payment.inactive) return payment.next
        return payment.step;
      },
    },
    actions: {
      async beforeLoadAction() {
        const requiredFields = this.requiredFields;
        this.$reset();
        this.requiredFields = requiredFields;

        const { insuredQuestions, jointInsuredQuestions } =
          await questionsService.getUnderwritingQuestions();

        function getChildQuestions(questions) {
          const childQuestionsByParentId = {};
          for (const q of questions) {
            const question = useUnderwritingQuestion(q, pinia);

            const parentId = question.parentId;
            if (!parentId) continue;
            if (!childQuestionsByParentId[parentId]) childQuestionsByParentId[parentId] = [];
            childQuestionsByParentId[parentId].push(q);
          }
          return childQuestionsByParentId;
        }

        // NOTE(tim): In order to simplify question display for multiple insureds, we group the questions by label.
        //            Currently, Fairway does not provide a way to indicate related questions across insureds.
        //            Additionally, Fairway does not validate label uniqueness across forms leading to intentional duplicates.

        this.insuredChildQuestions = getChildQuestions(insuredQuestions);
        this.jointChildQuestions = getChildQuestions(jointInsuredQuestions);

        function addQuestionsToGroups(insuredKey, questions, groupPtr) {
          const uniqueQuestionCounter = {};

          for (const q of questions) {
            const question = useUnderwritingQuestion(q, pinia);

            if (question.parentId) continue;

            if (!uniqueQuestionCounter[question.label]) uniqueQuestionCounter[question.label] = 0;

            const key = `${question.label}-${uniqueQuestionCounter[question.label]++}`;

            if (!groupPtr[key]) groupPtr[key] = { label: question.label };
            groupPtr[key][insuredKey] = q;
          }
        }

        const questionGroups = {};
        addQuestionsToGroups('insured', insuredQuestions, questionGroups);
        addQuestionsToGroups('joint', jointInsuredQuestions, questionGroups);

        const questionGroupKeys = Object.keys(questionGroups);

        const insured = usePrimaryInsuredStore(pinia);
        const joint = useJointInsuredStore(pinia);

        for (const q of questionGroupKeys) {
          const { insured: insuredQuestion, joint: jointQuestion } = questionGroups[q];
          if (insuredQuestion && jointQuestion) {
            const storedInsuredQuestion = useUnderwritingQuestion(questionGroups[q].insured, pinia);
            storedInsuredQuestion.inputLabel = insured.first_name.model;
            storedInsuredQuestion.label = null;

            const storedJointQuestion = useUnderwritingQuestion(questionGroups[q].joint, pinia);
            storedJointQuestion.inputLabel = joint.first_name.model;
            storedJointQuestion.label = null;

            this.sharedRootQuestions.push({
              label: questionGroups[q].label,
              insuredQuestion,
              jointQuestion,
            });
          } else if (insuredQuestion) {
            this.insuredRootQuestions.push(insuredQuestion);
          } else if (jointQuestion) {
            this.jointRootQuestions.push(jointQuestion);
          }
        }

        return Boolean(insuredQuestions.length || jointInsuredQuestions.length);
      },
      clearBuild(insuredKey) {
        let buildKey = 'insuredBuild';
        if (insuredKey === INSURED_TYPES.JOINT) {
          buildKey = 'jointInsuredBuild';
        }
        this[buildKey].minWeight = null;
        this[buildKey].maxWeight = null;
        this[buildKey].rating = null;
        this[buildKey].okayToProceed = null;
        this[buildKey].exists = null;
        this[buildKey].valid = null;
      },
      async getBuild(insuredKey) {
        let buildKey = 'insuredBuild';
        if (insuredKey === INSURED_TYPES.JOINT) {
          buildKey = 'jointInsuredBuild';
        }

        const insured = useInsuredStore(insuredKey, pinia);
        const { minWeight, maxWeight, rating, okayToProceed } = await individualService.getBuild({
          id: insured.id,
          height: insured.height.model,
          weight: insured.weight.model,
        });
        this[buildKey].minWeight = minWeight;
        this[buildKey].maxWeight = maxWeight;
        this[buildKey].rating = rating;
        this[buildKey].okayToProceed = okayToProceed;
        this[buildKey].exists = !Object.values(this[buildKey]).every((v) => v === null);

        this[buildKey].valid =
          this[buildKey].okayToProceed ||
          (insured.weight >= minWeight && insured.weight <= maxWeight);
      },
      setStepRequirements(srq) {
        this.requiredFields.build = srq[REQS.BUILD];
      },
    },
  })(pinia, hot);
}
