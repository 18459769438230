import LoginStep from '#src/components/steps/LoginStep.vue';
import AgentSelector from '#src/components/AgentSelector.vue';
import DemoStep from '#src/components/steps/demo/DemoStep.vue';
import NameStep from '#src/components/steps/name/NameStep.vue';
import ReferralStep from '#src/components/steps/referral/ReferralStep.vue';
import CriticalFailureStep from '#src/components/steps/CriticalFailureStep.vue';
import { steps } from '#src/enumerations/step-enumeration';
import { markRaw, defineAsyncComponent } from 'vue';

const getStepComponent = {};

function addStep({ step, component }) {
  getStepComponent[step] = component;
}

function addLazyStep({ step, component }) {
  addStep({ step, component: markRaw(defineAsyncComponent(component)) });
}

addStep({ step: steps.NAME, component: markRaw(NameStep) });
addStep({ step: steps.AGENT_SIGN_UP, component: markRaw(AgentSelector) });
addStep({ step: steps.LOGIN, component: markRaw(LoginStep) });
addStep({ step: steps.REFERRAL, component: markRaw(ReferralStep) });
addStep({ step: steps.DEMO, component: markRaw(DemoStep) });
addStep({ step: steps.CRITICAL_FAILURE, component: markRaw(CriticalFailureStep) });

addLazyStep({
  step: steps.GENDER,
  component: () => import('#src/components/steps/gender/GenderStep.vue'),
});
addLazyStep({
  step: steps.HEALTH,
  component: () => import('#src/components/steps/health/HealthStep.vue'),
});
addLazyStep({
  step: steps.SMOKER,
  component: () => import('#src/components/steps/smoker/SmokerStep.vue'),
});
addLazyStep({
  step: steps.BUILD,
  component: () => import('#src/components/steps/build/BuildStep.vue'),
});
addLazyStep({
  step: steps.BIRTHDATE,
  component: () => import('#src/components/steps/birthdate/BirthdateStep.vue'),
});
addLazyStep({
  step: steps.COMPARE,
  component: () => import('#src/components/steps/compare/CompareStep.vue'),
});
addLazyStep({
  step: steps.BENEFIT_ESTIMATION,
  component: () => import('#src/components/steps/BenefitEstimationStep.vue'),
});
addLazyStep({
  step: steps.PRE_QUOTE_INFORMATION_STEP,
  component: () => import('#src/components/steps/PreQuoteInformationStep.vue'),
});
addLazyStep({
  step: steps.LIFE_INSURANCE_PRIORITY,
  component: () => import('#src/components/steps/LifeInsurancePriorityStep.vue'),
});
addLazyStep({
  step: steps.QUOTE,
  component: () => import('#src/components/steps/quote/QuoteStep.vue'),
});
addLazyStep({
  step: steps.OWNER,
  component: () => import('#src/components/steps/owner/OwnerStep.vue'),
});
addLazyStep({
  step: steps.CONTACT,
  component: () => import('#src/components/steps/contact/ContactStep.vue'),
});
addLazyStep({
  step: steps.SECONDARY_ADDRESSEE,
  component: () => import('#src/components/steps/secondary-addressee/SecondaryAddresseeStep.vue'),
});
addLazyStep({
  step: steps.ADDRESS,
  component: () => import('#src/components/steps/address/AddressStep.vue'),
});
addLazyStep({
  step: steps.EMPLOYER,
  component: () => import('#src/components/steps/employment/EmploymentStep.vue'),
});
addLazyStep({
  step: steps.PRIMARY_BENEFICIARY,
  component: () => import('#src/components/steps/beneficiary/BeneficiaryStep.vue'),
});
addLazyStep({
  step: steps.CONTINGENT_BENEFICIARY,
  component: () => import('#src/components/steps/beneficiary/BeneficiaryStep.vue'),
});
addLazyStep({
  step: steps.PERSONAL,
  component: () => import('#src/components/steps/personal/PersonalStep.vue'),
});
addLazyStep({
  step: steps.PHYSICIAN,
  component: () => import('#src/components/steps/physician/PhysicianStep.vue'),
});
addLazyStep({
  step: steps.UNDERWRITING,
  component: () => import('#src/components/steps/underwriting/UnderwritingStep.vue'),
});
addLazyStep({
  step: steps.EXAM_SCHEDULING,
  component: () => import('#src/components/steps/exam-scheduling/ExamSchedulingStep.vue'),
});
addLazyStep({
  step: steps.EXISTING_COVERAGE,
  component: () => import('#src/components/steps/existing-coverage/ExistingCoverageStep.vue'),
});
addLazyStep({
  step: steps.SIGNATURE,
  component: () => import('#src/components/steps/SignatureStep.vue'),
});
addLazyStep({
  step: steps.COMPLETED,
  component: () => import('#src/components/steps/completed/CompletedStep.vue'),
});
addLazyStep({
  step: steps.PAYMENT,
  component: () => import('#src/components/steps/payment/PaymentStep.vue'),
});
addLazyStep({
  step: steps.SIGNATURE_WAITING,
  component: () => import('#src/components/steps/signature-waiting/SignatureWaitingStep.vue'),
});
addLazyStep({
  step: steps.CALCULATOR,
  component: () => import('#src/components/steps/calculator/CalculatorStep.vue'),
});

export { getStepComponent };
