export const JOINT_MARITAL_STATUSES = [
  {
    title: 'Married',
    value: 'Married',
  },
  {
    title: 'Domestic Partner',
    value: 'Domestic Partner',
  },
];

export const ALL_MARITAL_STATUSES = [
  {
    title: 'Single',
    value: 'Single',
  },
  ...JOINT_MARITAL_STATUSES,
];
