<template>
  <v-card flat :color="props.noColor ? 'transparent' : 'section'" class="h-100 m-w-app">
    <div v-show="props.showLogo" class="mb-3 pt-5">
      <app-logo />
    </div>
    <div v-if="$slots.logo">
      <slot name="logo" />
    </div>
    <v-row
      v-if="$slots.title"
      data-testid="step-title"
      class="break-word pt-6 ma-0 px-3 white-space-prewrap w-100 font-weight-light pos-relative"
      justify="center"
      align="center"
      style="line-height: 1.5"
    >
      <h2
        :class="{
          'pb-8': !$slots.subtitle && !$slots.actions && !$slots.body,
        }"
      >
        <div style="max-width: 900px; align-self: center">
          <slot name="title" />
        </div>
      </h2>
    </v-row>
    <v-card-subtitle
      v-if="$slots.subtitle"
      :class="{ 'pt-0': $slots.title }"
      class="full-opacity"
      style="font-size: 16px"
    >
      <slot name="subtitle" />
    </v-card-subtitle>
    <v-card-text v-if="$slots.body" :class="{ 'pt-0': $slots.title, 'pb-0': $slots['sub-body'] }">
      <slot name="body" />
    </v-card-text>
    <v-card-text v-if="$slots['sub-body']" class="pt-0">
      <slot name="sub-body" />
    </v-card-text>
    <v-card-actions v-if="$slots.actions" class="pb-6">
      <slot name="actions" />
    </v-card-actions>
  </v-card>
</template>

<script setup>
import AppLogo from '#src/components/shared/AppLogo.vue';
const props = defineProps({
  showLogo: Boolean,
  noColor: Boolean,
});
</script>

<style lang="scss">
.break-word {
  word-break: break-word;
}
.theme--light.v-card > .v-card__text,
.theme--light.v-card > .v-card__subtitle {
  color: #000000a6;
  font-size: 1rem;
}
.theme--dark.v-card > .v-card__text,
.theme--dark.v-card > .v-card__subtitle {
  color: white;
  font-size: 1rem;
}
</style>
