import { WidgetCommunicatorService } from '#src/services/widget-communicator.service.js';
import { useInstanceSettingsStore } from '#src/stores/instance-settings.js';

function goTo(pinia, element, container, embedBehavior = 'smooth', scrollToTop = false) {
  let elOffsetY = 0;
  if (element) {
    let theElement = element;
    if (typeof selector === 'string') {
      theElement = document.querySelector(element);
    }
    elOffsetY = theElement.getBoundingClientRect().top;
  }

  const instance = useInstanceSettingsStore(pinia);
  if (instance.isInternallyEmbedded) {
    const widgetCommunicatorService = new WidgetCommunicatorService();
    widgetCommunicatorService.focusIframe(elOffsetY, embedBehavior);
    return;
  }

  const containerEl = document.querySelector(container);
  if (!containerEl) return;
  let top;
  if (scrollToTop) {
    top = 0;
  } else {
    top = containerEl.scrollTop + elOffsetY - window.innerHeight / 2;
  }
  containerEl.scrollTo({
    top,
    behavior: 'smooth',
  });
}

export function scrollToInvalid(pinia, behavior = 'smooth') {
  let errors = document.getElementsByClassName('v-field--error');
  if (!errors?.length) {
    errors = document.getElementsByClassName('v-input--error');
  }
  if (!errors?.length) return;

  const instance = useInstanceSettingsStore(pinia);
  if (instance.isExternallyEmbedded) return goTo(pinia, errors[[0]], '#step-container', behavior);
  errors[[0]].scrollIntoView({ behavior });
}

export function scrollToTop(pinia, behavior = 'smooth') {
  const instance = useInstanceSettingsStore(pinia);
  if (instance.isExternallyEmbedded) return goTo(pinia, null, '#step-container', behavior, true);
  const container = document.getElementById('application-flow');
  if (container) container.scrollIntoView({ behavior });
}
