import { dateToYears } from '#src/util/helpers.js';
import { insuredQuoteData } from '#src/structures/quote-params/quote-params-helpers.js';
import { PRODUCTS } from '#src/structures/ProductType.js';
import { MAX_INCOME_AGE } from '#src/data/quoteParams.js';
import { MODES } from '#src/data/modes.js';

export const PAYOUT_AMOUNT_SOLVE = 'Payout Amount';
export const PREMIUM_REQUIRED_SOLVE = 'Premium Required';

export default function IncomeRider() {
  return {
    get type() {
      return PRODUCTS.INCOME_RIDER;
    },
    get isIncomeRider() {
      return true;
    },
    get display() {
      return 'Lifetime Income';
    },
    get defaults() {
      return {
        categories: [this.requestCategory],
        save_age: false,
        solve: PAYOUT_AMOUNT_SOLVE,
        premium: 200000,
        income_amount: 20000,
        joint_income: false,
      };
    },
    get requestCategory() {
      return 'annuity.income_rider';
    },
    get modes() {
      return [
        {
          value: MODES.ANNUALLY,
          title: 'Paying Annually',
        },
      ];
    },
    get solves() {
      return [
        {
          text: 'Solve for Payout Amount',
          value: PAYOUT_AMOUNT_SOLVE,
          display: 'income_amount',
          displayQuantifier: 'Annual Income',
        },
        {
          text: 'Solve for Premium Required',
          value: PREMIUM_REQUIRED_SOLVE,
          display: 'premium',
          displayQuantifier: 'Single Pay',
        },
      ];
    },
    get naiveSolves() {
      return [
        { text: 'I know my initial investment', value: PAYOUT_AMOUNT_SOLVE },
        { text: 'I know how much I want', value: PREMIUM_REQUIRED_SOLVE },
      ];
    },
    get jointIncomeOptions() {
      return [
        {
          title: 'Yes',
          value: true,
          dataTestid: `joint-income-yes`,
        },
        {
          title: 'No',
          value: false,
          dataTestid: `joint-income-no`,
        },
      ];
    },
    showIncomeAmount(params) {
      return params.solve === PREMIUM_REQUIRED_SOLVE;
    },
    showPremium(params) {
      return params.solve === PAYOUT_AMOUNT_SOLVE;
    },
    renderRules(params) {
      return {
        incomeAmount: this.showIncomeAmount(params),
        premium: this.showPremium(params),
        incomeStartAge: true,
        solve: true,
        jointIncome: true,
      };
    },
    eAppParser(model = {}) {
      let income_start_age = model?.income_start_age;
      if (model?.age) {
        if (income_start_age < model.age) {
          income_start_age = Math.max(model.age + 10, 66);
        }
      }

      return {
        income_amount: model.income_amount || this.defaults.income_amount,
        premium: model.premium || this.defaults.premium,
        joint_income: Boolean(model.joint_income),
        solve: this.solves.some((s) => s.value === model.solve) ? model.solve : this.defaults.solve,
        income_start_age,
      };
    },
    generateIncomeAges(birthdate) {
      const years = dateToYears(birthdate);
      const ages = [];
      for (let i = 0; i < MAX_INCOME_AGE - years; i++) {
        ages.push(years + i);
      }
      return ages;
    },
    toQuoteRequest({ eAppParams = {}, insured, otherInsured, agentId }) {
      const paramObj = eAppParams;

      const renderRules = this.renderRules(paramObj);

      const extraData = {};
      if (renderRules.incomeAmount) extraData.income_amount = paramObj.income_amount;
      if (renderRules.premium) extraData.premium = paramObj.premium;
      if (paramObj.joint_income) extraData.joint_party = insuredQuoteData(otherInsured);

      return {
        agent: { id: agentId },
        compare: false,
        ...insuredQuoteData(insured),

        state: eAppParams.state,
        categories: this.defaults.categories,
        selected_type: eAppParams.selected_type,

        mode: 1,
        solve: paramObj.solve,
        save_age: paramObj.save_age,
        income_start_age: paramObj.income_start_age,
        joint_income: Boolean(paramObj.joint_income),
        ...extraData,
      };
    },
  };
}
