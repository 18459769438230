import modes from '#src/data/modes.js';
import { insuredQuoteData } from '#src/structures/quote-params/quote-params-helpers.js';
import { isBoolean } from '#src/util/helpers.js';
import { PRODUCTS } from '#src/structures/ProductType.js';

export const WHOLE_LIFE_PLAN_TYPES = {
  NON_PAR: 'Whole Life - Non Participating',
  STANDARD: 'Whole Life',
};

export default function WholeLife() {
  return {
    get display() {
      return 'Whole Life';
    },
    get type() {
      return PRODUCTS.WHOLE_LIFE;
    },
    get isPermanent() {
      return true;
    },
    get defaults() {
      return {
        pay_duration: -1,
        solve: 'Base Premium',
        categories: [this.requestCategory],
        save_age: false,
      };
    },
    get solves() {
      return {
        premium_solves: [{ value: 'Base Premium', text: 'Premium' }],
        death_benefit_solves: [{ value: 'Base Death Benefit', text: 'Death Benefit' }],
      };
    },
    get requestCategory() {
      return 'whole_life.none';
    },
    get modes() {
      return modes;
    },
    showFaceAmount({ solve }) {
      return solve === 'Base Premium';
    },
    showPremium({ solve }) {
      return solve === 'Base Death Benefit';
    },
    renderRules(params) {
      const faceAmount = this.showFaceAmount(params);
      const premium = this.showPremium(params);
      return {
        saveAge: true,
        faceAmount,
        payDuration: true,
        premium,
        solve: true,
        insuredHealth: true,
        mode: true
      };
    },
    get payDurationOptions() {
      return [
        {
          value: -1,
          title: 'Paid up at age 100',
        },
        {
          value: 20,
          title: '20 Pay',
        },
        {
          value: 15,
          title: '15 Pay',
        },
        {
          value: 10,
          title: '10 Pay',
        },
        {
          value: 1,
          title: 'Single Pay',
        },
      ];
    },
    eAppParser(model = {}) {
      let pay_duration = model.pay_duration;

      if (!this.payDurationOptions.some(({ value }) => value === pay_duration)) {
        pay_duration = this.defaults.pay_duration;
      }

      pay_duration = +pay_duration;

      const allSolves = [...this.solves.premium_solves, ...this.solves.death_benefit_solves];

      const useModelSolve = allSolves.some(({ value }) => value === model?.solve);
      let solve = this.defaults.solve;
      if (useModelSolve) solve = model?.solve;
      let saveAge = model.save_age;
      if (!isBoolean(saveAge)) saveAge = this.defaults.save_age;
      return {
        pay_duration,
        premium: model?.premium,
        solve,
        face_amount: model?.face_amount || model?.initial_death_benefit || 1000000,
        save_age: saveAge,
      };
    },
    toQuoteRequest({ eAppParams = {}, insured, agentId }) {
      const premiumOrFaceAmount = {};
      const renderRules = this.renderRules(eAppParams);
      if (renderRules.faceAmount) {
        premiumOrFaceAmount.face_amount = eAppParams.face_amount;
      }
      if (renderRules.premium) {
        premiumOrFaceAmount.premium = eAppParams.premium;
      }
      return {
        agent: { id: agentId },
        compare: false,

        ...insuredQuoteData(insured),

        selected_type: eAppParams.selected_type,
        state: eAppParams.state,
        categories: this.defaults.categories,

        mode: eAppParams.mode,
        pay_duration: eAppParams.pay_duration,
        solve: eAppParams.solve,
        save_age: eAppParams.save_age,
        ...premiumOrFaceAmount,
      };
    },
  };
}
