<template>
  <v-col cols="12">
    <v-row class="pa-1">
      <template v-if="showPrevious">
        <v-btn
          :loading="loading"
          tabindex="-1"
          class="text-none rounded-te-0 rounded-be-0 nav-btn-width"
          :data-testid="loading ? 'previous-button-loading' : 'previous-button'"
          depressed
          variant="outlined"
          @click="emit('previous')"
        >
          Previous
        </v-btn>
        <v-menu offset-y top>
          <template #activator="{ props }">
            <v-btn
              tabindex="-1"
              :disabled="loading"
              variant="outlined"
              v-bind="props"
              class="text-none px-0 ml-0 rounded-ts-0 rounded-bs-0"
              depressed
              style="min-width: 1rem; border-left: 0"
              title="Additional Previous Options"
            >
              <v-icon :icon="mdiMenuUp" />
            </v-btn>
          </template>
          <v-list>
            <v-list-item link @click="emit('previous')"> Previous </v-list-item>
            <v-list-item v-if="canGoToQuotes" link @click="emit('go-to-quote-step')">
              Go to quotes
            </v-list-item>
            <v-list-item link @click="emit('go-to-name-step')"> Go to beginning </v-list-item>
          </v-list>
        </v-menu>
      </template>
      <v-spacer />
      <v-btn
        v-if="showSkip"
        :data-testid="loading ? 'skip-button-loading' : 'skip-button'"
        color="error"
        class="text-none nav-btn-width"
        variant="outlined"
        :loading="loading"
        @click="emit('skip')"
      >
        Skip
      </v-btn>
      <v-btn
        v-else-if="showNext"
        :data-testid="loading ? 'next-button-loading' : 'next-button'"
        color="primary"
        class="text-none"
        variant="flat"
        :loading="loading"
        v-bind="nextAction"
      >
        {{ nextText }}
      </v-btn>
    </v-row>
    <v-row v-if="disclosure" class="ma-0 mt-3 small-text text-center reg-subtitle" justify="center">
      {{ disclosure }}
    </v-row>
  </v-col>
</template>
<script setup>
import { steps } from '#src/enumerations/step-enumeration';
import { storeToRefs } from 'pinia';
import { useFlowStore } from '#src/stores/flow';
import { mdiMenuUp } from '@mdi/js';
import { useInstanceSettingsStore } from '#src/stores/instance-settings';
import { computed, inject } from 'vue';

defineProps({
  showFooter: Boolean,
  showPrevious: Boolean,
  showNext: Boolean,
  showSkip: Boolean,
  loading: Boolean,
});

const emit = defineEmits(['previous', 'go-to-quote-step', 'go-to-name-step', 'skip', 'next']);

const pinia = inject('pinia');

const { disclosure } = storeToRefs(useInstanceSettingsStore(pinia));
const { step, activeStep } = storeToRefs(useFlowStore(pinia));
const canGoToQuotes = computed(() => Object.values(steps).includes(step.value));

const nextAction = computed(() => activeStep.value?.nextAction || { onClick: () => emit('next') });

const nextText = computed(() => activeStep.value?.nextText || 'Next');
</script>

<style lang="scss">
.nav-btn-width {
  width: 6rem;
}
</style>
