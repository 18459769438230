<template>
  <div>
    <v-autocomplete
      :id="props.dataTestid"
      v-model="model"
      data-lpignore="true"
      variant="outlined"
      density="compact"
      :custom-filter="props.customFilter"
      :aria-label="$attrs?.label"
      :data-testid="props.dataTestid"
      :menu-props="{ offsetY: true, auto: true }"
      :items="props.items"
      :class="inputClasses"
      :color="color"
      :base-color="baseColor"
      :error-messages="errorMessages"
      v-bind="$attrs"
      :auto-select-first="true"
      @update:model-value="debounceAndValidate"
      @focus="handleFocus"
    >
      <template #append-inner>
        <active-save-indicator :controller="savingBuffer" />
      </template>
      <template v-if="props.otherItem" #no-data>
        <v-list-item :title="props.otherItem.title" @click="model = props.otherItem.value" />
      </template>
      <template v-if="$slots.message" #message>
        <slot name="message" />
      </template>
    </v-autocomplete>
  </div>
</template>

<script setup>
import ActiveSaveIndicator from '#src/components/shared/ActiveSaveIndicator.vue';
import { useInput, useInputProps } from '#src/composables/savable-input.composable';
import { inject, nextTick } from 'vue';

const pinia = inject('pinia');
const props = defineProps({
  ...useInputProps(),
  items: {
    type: Array,
    required: true,
  },
  customFilter: {
    type: Function,
    required: false,
    default: null,
  },
});

function handleFocus(e) {
  nextTick(() => {
    e.target.select();
  });
}

const { model, inputClasses, color, baseColor, errorMessages, savingBuffer, debounceAndValidate } =
  useInput(props, pinia, 0);
</script>
