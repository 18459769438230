export const steps = {
  DEMO: -100,
  LOGIN: -102,
  CALCULATOR: -101,
  CRITICAL_FAILURE: -999,
  AGENT_SIGN_UP: -103,
  REFERRAL: 105,

  // Quote Step Enumerations
  NAME: -7,
  GENDER: -6,
  HEALTH: -5,
  SMOKER: -4,
  BUILD: -3,
  BIRTHDATE: -2,
  LIFE_INSURANCE_PRIORITY: 102,
  BENEFIT_ESTIMATION: 101,
  PRE_QUOTE_INFORMATION_STEP: 103,
  COMPARE: -1,
  QUOTE: 0,

  // App Enumerations
  CONTACT: 1,
  ADDRESS: 2,
  EMPLOYER: 3,
  OWNER: 4,
  PRIMARY_BENEFICIARY: 5,
  CONTINGENT_BENEFICIARY: 6,
  PERSONAL: 7,
  EXISTING_COVERAGE: 8,
  PHYSICIAN: 9,
  SECONDARY_ADDRESSEE: 104,
  UNDERWRITING: 10,
  PAYMENT: 11,
  EXAM_SCHEDULING: 12,
  SIGNATURE_WAITING: 13,
  SIGNATURE: 14,

  COMPLETED: 15,
};

export const APPLY_ROUTE_GA = {
  [steps.NAME]: 'Name',
  [steps.AGENT_SIGN_UP]: 'SelectAgent',
  [steps.LOGIN]: 'Login',
  [steps.DEMO]: 'Demo',
  [steps.GENDER]: 'Gender',
  [steps.HEALTH]: 'Health',
  [steps.SMOKER]: 'Smoker',
  [steps.BUILD]: 'Build',
  [steps.BIRTHDATE]: 'Birthdate',
  [steps.REFERRAL]: 'Referral',
  [steps.COMPARE]: 'Protection',
  [steps.BENEFIT_ESTIMATION]: 'Benefit Estimation',
  [steps.PRE_QUOTE_INFORMATION_STEP]: 'PreQuoteInformation',
  [steps.LIFE_INSURANCE_PRIORITY]: 'Priority',
  [steps.QUOTE]: 'Quote',
  [steps.OWNER]: 'Owner',
  [steps.CONTACT]: 'Personal',
  [steps.SECONDARY_ADDRESSEE]: 'SecondaryAddressee',
  [steps.ADDRESS]: 'Address',
  [steps.EMPLOYER]: 'Employer',
  [steps.PRIMARY_BENEFICIARY]: 'PrimaryBeneficiary',
  [steps.CONTINGENT_BENEFICIARY]: 'ContingentBeneficiary',
  [steps.PERSONAL]: 'Personal2',
  [steps.PHYSICIAN]: 'Physician',
  [steps.UNDERWRITING]: 'Verbatim',
  [steps.EXAM_SCHEDULING]: 'ExamScheduling',
  [steps.EXISTING_COVERAGE]: 'ExistingCoverage',
  [steps.SIGNATURE]: 'Signature',
  [steps.COMPLETED]: 'Completed',
  [steps.PAYMENT]: 'Payment',
  [steps.SIGNATURE_WAITING]: 'SignatureWaiting',
  [steps.CALCULATOR]: 'Calculator',
  [steps.CRITICAL_FAILURE]: 'CriticalFailure',
};

export const APPLY_ROUTE_META_NAMES = {
  [steps.NAME]: 'Name',
  [steps.AGENT_SIGN_UP]: 'Sign Up',
  [steps.LOGIN]: 'Login',
  [steps.DEMO]: 'Demo',
  [steps.GENDER]: 'Gender',
  [steps.HEALTH]: 'Health',
  [steps.SMOKER]: 'Smoker',
  [steps.BUILD]: 'Build',
  [steps.BIRTHDATE]: 'Birthdate',
  [steps.REFERRAL]: 'Referral',
  [steps.COMPARE]: 'Compare',
  [steps.BENEFIT_ESTIMATION]: 'Benefit Estimation',
  [steps.PRE_QUOTE_INFORMATION_STEP]: 'Pre-Quote Information',
  [steps.LIFE_INSURANCE_PRIORITY]: 'Top Priority',
  [steps.QUOTE]: 'Quote',
  [steps.OWNER]: 'Owner',
  [steps.CONTACT]: 'Contact',
  [steps.SECONDARY_ADDRESSEE]: 'Secondary Addressee',
  [steps.ADDRESS]: 'Address',
  [steps.EMPLOYER]: 'Employer',
  [steps.PRIMARY_BENEFICIARY]: 'Primary Beneficiary',
  [steps.CONTINGENT_BENEFICIARY]: 'Contingent Beneficiary',
  [steps.PERSONAL]: 'Personal',
  [steps.PHYSICIAN]: 'Physician',
  [steps.UNDERWRITING]: 'Underwriting',
  [steps.EXAM_SCHEDULING]: 'Exam Scheduling',
  [steps.EXISTING_COVERAGE]: 'Existing Coverage',
  [steps.SIGNATURE]: 'Signature',
  [steps.COMPLETED]: 'Completed',
  [steps.PAYMENT]: 'Payment',
  [steps.SIGNATURE_WAITING]: 'Signature Options',
  [steps.CALCULATOR]: 'Calculator',
  [steps.CRITICAL_FAILURE]: 'Critical Failure',
};

export const stepToApiFriendlyRoute = (step) =>
  APPLY_ROUTE_META_NAMES[step].toLowerCase().replace(' ', '_');

const stepToRouteName = {};
Object.values(steps).forEach((step) => {
  stepToRouteName[step] = APPLY_ROUTE_GA[step];
});

export { stepToRouteName };
