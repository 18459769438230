import { INSURED_TYPES, useInsuredStore } from '#src/stores/insured.js';
import {
  mdiAsterisk,
  mdiEarth,
  mdiCalendar,
  mdiCardAccountDetails,
  mdiHomeGroup,
  mdiMessageReply,
  mdiTag,
  mdiHumanMaleHeight,
  mdiScaleBathroom,
  mdiEmail,
  mdiPhone,
  mdiPercent,
  mdiHeartPulse,
  mdiSmoking,
  mdiRing,
  mdiBank,
  mdiAccount,
} from '@mdi/js';

import {
  SelectField,
  TextField,
  DateField,
  CurrencyField,
  SsnField,
  AutocompleteField,
  YesNoField,
  FormFieldAdapter,
  CustomButtonsField,
  IntegerField,
  BirthdateField,
  EmailField,
  PhoneField,
  AddressField,
  AdvancedHealthField,
  RatingField,
  RadioField,
  RoutingNumberField,
  AccountNumberField,
} from '#src/components/form-builder/FormBuilderTemplates.js';

import { BANKRUPTCY_TYPE_OPTIONS } from '#src/data/bankruptcy.js';
import { STATES_AND_COUNTRIES, COUNTRIES, STATES } from '#src/data/states-and-countries.js';
import { GENDER_ITEMS } from '#src/data/genders.js';
import { VISA_OPTIONS } from '#src/data/visas.js';
import heights from '#src/data/heights.js';
import { INSURED_RELATIONSHIP_OPTIONS } from '#src/data/relationships.js';
import { noExamReasons } from '#src/data/no-exam-reasons.js';

import { computed } from 'vue';
import { SMOKING_STATUSES } from '#src/structures/SmokingUsage.js';
import { ALL_MARITAL_STATUSES, JOINT_MARITAL_STATUSES } from '#src/data/maritalStatuses.js';

function storeKeyPrefix(storeKey, testId) {
  return `${storeKey}-${testId}`;
}

export function useInsuredAddressProperty(
  pinia,
  module,
  fieldProps = {
    countryVisible: false,
    yearsVisible: false,
    yearsAutofocus: false,
    maxYears: 0,
    addressPrefix: '',
    autofocus: false,
  },
  switcher = { manualEntry: false, setManualEntry: () => {} },
) {
  const dataTestId = (v) => storeKeyPrefix(module, v);
  const storeKey = (v) => `${fieldProps.addressPrefix || ''}${v}`;

  const fieldOptions = {
    autofill: {
      autofocus: fieldProps.autofocus,
      dataTestid: dataTestId('address-autofill'),
      label: 'Address',
      placeholder: 'Start typing your address',
      autocomplete: 'street-address',
      savableProperty: useInsuredStore(module, pinia)[storeKey('street_address')],
      save: () => {},
    },
    street: {
      dataTestid: dataTestId('street-address'),
      label: 'Street Address',
      placeholder: 'Street Address',
      savableProperty: useInsuredStore(module, pinia)[storeKey('street_address')],
      overrideSave: () =>
        useInsuredStore(module, pinia).saveAttributes([storeKey('street_address')]),
    },
    state: {
      dataTestid: dataTestId('state'),
      label: 'State',
      placeholder: 'State',
      savableProperty: useInsuredStore(module, pinia)[storeKey('state')],
      overrideSave: () => useInsuredStore(module, pinia).saveAttributes([storeKey('state')]),
    },
    city: {
      dataTestid: dataTestId('city'),
      label: 'City',
      placeholder: 'City',
      savableProperty: useInsuredStore(module, pinia)[storeKey('city')],
      overrideSave: () => useInsuredStore(module, pinia).saveAttributes([storeKey('city')]),
    },
    zip: {
      dataTestid: dataTestId('zip'),
      label: 'Zip',
      placeholder: 'Zip',
      savableProperty: useInsuredStore(module, pinia)[storeKey('zip')],
      overrideSave: () => useInsuredStore(module, pinia).saveAttributes([storeKey('zip')]),
    },
    country: {
      visible: false,
    },
    years_at_address: {
      visible: false,
    },
  };

  if (fieldProps.countryVisible) {
    fieldOptions.country = {
      visible: fieldProps.countryVisible,
      dataTestid: dataTestId('country'),
      label: 'Country',
      placeholder: 'Country',
      savableProperty: useInsuredStore(module, pinia)[storeKey('country')],
      overrideSave: () => useInsuredStore(module, pinia).saveAttributes([storeKey('country')]),
    };
  }

  if (fieldProps.yearsVisible) {
    fieldOptions.years_at_address = {
      autofocus: fieldProps.yearsAutofocus,
      visible: fieldProps.yearsVisible,
      dataTestid: dataTestId('years-at-address'),
      label: 'Years at Address',
      placeholder: 'Years at Address',
      savableProperty: useInsuredStore(module, pinia)[storeKey('years_at_address')],
      overrideSave: () =>
        useInsuredStore(module, pinia).saveAttributes([storeKey('years_at_address')]),
    };
  }

  fieldOptions.autofill.save = () => {
    const attributes = [
      storeKey('street_address'),
      storeKey('city'),
      storeKey('state'),
      storeKey('zip'),
    ];

    const insured = useInsuredStore(module, pinia);

    if (insured[storeKey('country')].model) attributes.push(storeKey('country'));

    return insured.saveAttributes(attributes);
  };

  return AddressField({
    switcher: { manualEntry: switcher.manualEntry, setManualEntry: switcher.setManualEntry },
    fieldOptions,
  });
}

function genBuilderProps(key, additionalProps) {
  return {
    mdColSize: additionalProps?.mdColSize || 6,
    colSize: 12,
    key,
    slots: additionalProps?.slots || undefined,
  };
}

export function useInsuredFirstNameProperty(pinia, module, additionalProps = {}) {
  return FormFieldAdapter(
    TextField({
      prependInnerIcon: mdiAccount,
      dataTestid: storeKeyPrefix(module, 'first-name'),
      label: 'First Name',
      placeholder: 'First Name',
      savableProperty: useInsuredStore(module, pinia).first_name,
    }),
    genBuilderProps('first_name', additionalProps),
    {
      overrideSave: () => useInsuredStore(module, pinia).saveAttributes(['first_name']),
      ...additionalProps,
    },
  );
}

export function useInsuredLastNameProperty(pinia, module, additionalProps = {}) {
  return FormFieldAdapter(
    TextField({
      prependInnerIcon: mdiAccount,
      dataTestid: storeKeyPrefix(module, 'last-name'),
      label: 'Last Name',
      placeholder: 'Last Name',
      savableProperty: useInsuredStore(module, pinia).last_name,
    }),
    genBuilderProps('last_name', additionalProps),
    {
      overrideSave: () => useInsuredStore(module, pinia).saveAttributes(['last_name']),
      additionalProps,
    },
  );
}

export function useInsuredGenderProperty(pinia, module, additionalProps = {}) {
  return FormFieldAdapter(
    SelectField({
      label: 'Gender',
      placeholder: 'Gender',
      dataTestid: storeKeyPrefix(module, 'gender'),
      items: GENDER_ITEMS,
      savableProperty: useInsuredStore(module, pinia).gender,
    }),
    genBuilderProps('gender', additionalProps),
    {
      overrideSave: () => useInsuredStore(module, pinia).saveAttributes(['gender']),
      ...additionalProps,
    },
  );
}
export function useInsuredHealthRatingProperty(pinia, module, additionalProps = {}) {
  return FormFieldAdapter(
    RatingField({
      label: 'Health',
      dataTestid: storeKeyPrefix(module, 'health'),
      prependInnerIcon: mdiHeartPulse,
      savableProperty: useInsuredStore(module, pinia).health,
    }),
    genBuilderProps('health', additionalProps),
    {
      overrideSave: () => useInsuredStore(module, pinia).saveAttributes(['health']),
      ...additionalProps,
    },
  );
}
export function useInsuredAdvancedHealthProperty(pinia, module, additionalProps = {}) {
  return FormFieldAdapter(
    AdvancedHealthField({
      label: 'Health',
      dataTestid: storeKeyPrefix(module, 'health'),
      prependInnerIcon: mdiHeartPulse,
      savableProperty: useInsuredStore(module, pinia).health,
    }),
    genBuilderProps('last_name', additionalProps),
    {
      overrideSave: () => useInsuredStore(module, pinia).saveAttributes(['health']),
      ...additionalProps,
    },
  );
}
export function useInsuredGenderButtonsProperty(pinia, module, additionalProps = {}) {
  return FormFieldAdapter(
    CustomButtonsField({
      dataTestid: storeKeyPrefix(module, 'gender'),
      items: GENDER_ITEMS,
      size: 'large',
      savableProperty: useInsuredStore(module, pinia).gender,
    }),
    genBuilderProps('gender', additionalProps),
    {
      overrideSave: () => useInsuredStore(module, pinia).saveAttributes(['gender']),
      ...additionalProps,
    },
  );
}
export function useInsuredSmokerButtonsProperty(pinia, module, additionalProps = {}) {
  return FormFieldAdapter(
    CustomButtonsField({
      dataTestid: storeKeyPrefix(module, 'smoker'),
      items: SMOKING_STATUSES,
      savableProperty: useInsuredStore(module, pinia).smoker,
    }),
    genBuilderProps('smoker', additionalProps),
    {
      overrideSave: () => useInsuredStore(module, pinia).saveAttributes(['smoker']),
      ...additionalProps,
    },
  );
}
export function useInsuredSmokerSelectProperty(pinia, module, additionalProps = {}) {
  return FormFieldAdapter(
    SelectField({
      prependInnerIcon: mdiSmoking,
      label: 'Smoking Usage',
      dataTestid: storeKeyPrefix(module, 'smoker'),
      items: SMOKING_STATUSES,
      savableProperty: useInsuredStore(module, pinia).smoker,
    }),
    genBuilderProps('smoker', additionalProps),
    {
      overrideSave: () => useInsuredStore(module, pinia).saveAttributes(['smoker']),
      ...additionalProps,
    },
  );
}
export function useInsuredMaritalStatusProperty(pinia, module, additionalProps = {}) {
  let items = ALL_MARITAL_STATUSES;
  if (module === INSURED_TYPES.JOINT) items = JOINT_MARITAL_STATUSES;
  return FormFieldAdapter(
    SelectField({
      label: 'Marital Status',
      placeholder: 'Marital Status',
      dataTestid: storeKeyPrefix(module, 'marital-status'),
      prependInnerIcon: mdiRing,
      items,
      savableProperty: useInsuredStore(module, pinia).marital_status,
    }),
    genBuilderProps('marital_status', additionalProps),
    {
      overrideSave: () => useInsuredStore(module, pinia).saveAttributes(['marital_status']),
      ...additionalProps,
    },
  );
}

export function useInsuredHeightProperty(pinia, module, additionalProps = {}) {
  return FormFieldAdapter(
    AutocompleteField({
      label: 'Height',
      placeholder: 'Height',
      dataTestid: storeKeyPrefix(module, 'height'),
      prependInnerIcon: mdiHumanMaleHeight,
      items: heights,
      savableProperty: useInsuredStore(module, pinia).height,
    }),
    genBuilderProps('height', additionalProps),
    {
      overrideSave: () => useInsuredStore(module, pinia).saveAttributes(['height']),
      ...additionalProps,
    },
  );
}
export function useInsuredEmailProperty(pinia, module, additionalProps = {}) {
  return FormFieldAdapter(
    EmailField({
      label: 'Email',
      placeholder: 'your.email@example.com',
      dataTestid: storeKeyPrefix(module, 'email'),
      prependInnerIcon: mdiEmail,
      savableProperty: useInsuredStore(module, pinia).email,
    }),
    genBuilderProps('email', additionalProps),
    {
      overrideSave: () => useInsuredStore(module, pinia).saveAttributes(['email']),
      ...additionalProps,
    },
  );
}
export function useInsuredWeightProperty(pinia, module, additionalProps = {}) {
  return FormFieldAdapter(
    IntegerField({
      dataTestid: storeKeyPrefix(module, 'weight'),
      prependInnerIcon: mdiScaleBathroom,
      min: 1,
      max: 999,
      savableProperty: useInsuredStore(module, pinia).weight,
    }),
    genBuilderProps('weight', additionalProps),
    {
      overrideSave: () => useInsuredStore(module, pinia).saveAttributes(['weight']),
      ...additionalProps,
    },
  );
}
export function useInsuredBirthdateProperty(pinia, module, additionalProps = {}) {
  return FormFieldAdapter(
    DateField({
      dataTestid: storeKeyPrefix(module, 'birthdate'),
      label: 'Birthdate',
      placeholder: 'mm/dd/yyyy',
      prependInnerIcon: mdiCalendar,
      savableProperty: useInsuredStore(module, pinia).birthdate,
    }),
    genBuilderProps('birthdate', additionalProps),
    {
      overrideSave: () => useInsuredStore(module, pinia).saveAttributes(['birthdate']),
      ...additionalProps,
    },
  );
}
export function useInsuredSplitBirthdateProperty(pinia, module, additionalProps = {}) {
  return FormFieldAdapter(
    BirthdateField({
      dataTestid: storeKeyPrefix(module, 'birthdate'),
      savableProperty: useInsuredStore(module, pinia).birthdate,
    }),
    genBuilderProps('birthdate', additionalProps),
    {
      overrideSave: () => useInsuredStore(module, pinia).saveAttributes(['birthdate']),
      ...additionalProps,
    },
  );
}
export function useInsuredIncomeProperty(pinia, module, additionalProps = {}) {
  return FormFieldAdapter(
    CurrencyField({
      dataTestid: storeKeyPrefix(module, 'income'),
      label: 'Annual Income',
      placeholder: 'Annual Income',
      savableProperty: useInsuredStore(module, pinia).income,
    }),
    genBuilderProps('income', additionalProps),
    {
      overrideSave: () => useInsuredStore(module, pinia).saveAttributes(['income']),
      ...additionalProps,
    },
  );
}
export function useInsuredAssetsProperty(pinia, module, additionalProps = {}) {
  return FormFieldAdapter(
    CurrencyField({
      dataTestid: storeKeyPrefix(module, 'assets'),
      label: 'Assets',
      placeholder: 'Assets',
      savableProperty: useInsuredStore(module, pinia).assets,
    }),
    genBuilderProps('assets', additionalProps),
    {
      overrideSave: () => useInsuredStore(module, pinia).saveAttributes(['assets']),
      ...additionalProps,
    },
  );
}
export function useInsuredLiabilitiesProperty(pinia, module, additionalProps = {}) {
  return FormFieldAdapter(
    CurrencyField({
      dataTestid: storeKeyPrefix(module, 'liabilities'),
      label: 'Liabilities',
      placeholder: 'Liabilities',
      savableProperty: useInsuredStore(module, pinia).liabilities,
    }),
    genBuilderProps('liabilities', additionalProps),
    {
      overrideSave: () => useInsuredStore(module, pinia).saveAttributes(['liabilities']),
      ...additionalProps,
    },
  );
}
export function useInsuredSsnProperty(pinia, module, additionalProps = {}) {
  return FormFieldAdapter(
    SsnField({
      dataTestid: storeKeyPrefix(module, 'ssn'),
      label: 'Social Security Number',
      placeholder: 'Social Security Number',
      prependInnerIcon: mdiAsterisk,
      savableProperty: useInsuredStore(module, pinia).ssn,
    }),
    genBuilderProps('ssn', additionalProps),
    {
      overrideSave: () => useInsuredStore(module, pinia).saveAttributes(['ssn']),
      ...additionalProps,
    },
  );
}
export function useInsuredBirthLocationProperty(pinia, module, additionalProps = {}) {
  return FormFieldAdapter(
    AutocompleteField({
      dataTestid: storeKeyPrefix(module, 'birth-location'),
      label: 'Birth State or Country',
      placeholder: 'Birth State or Country',
      items: STATES_AND_COUNTRIES,
      prependInnerIcon: mdiEarth,
      savableProperty: useInsuredStore(module, pinia).birth_location,
    }),
    genBuilderProps('birth_location', additionalProps),
    {
      overrideSave: () => useInsuredStore(module, pinia).saveAttributes(['birth_location']),
      ...additionalProps,
    },
  );
}
export function useInsuredUsCitizenProperty(pinia, module, additionalProps = {}) {
  return FormFieldAdapter(
    YesNoField({
      dataTestid: storeKeyPrefix(module, 'us-citizen'),
      label: computed(() => {
        const insured = useInsuredStore(module, pinia);
        return `Is ${insured.first_name.model} a U.S. Citizen?`;
      }),
      savableProperty: useInsuredStore(module, pinia).us_citizen,
    }),
    genBuilderProps('us_citizen', additionalProps),
    {
      overrideSave: () => useInsuredStore(module, pinia).saveAttributes(['us_citizen']),
      ...additionalProps,
    },
  );
}
export function useInsuredVisaTypeProperty(pinia, module, additionalProps = {}) {
  return FormFieldAdapter(
    AutocompleteField({
      dataTestid: storeKeyPrefix(module, 'visa-type'),
      label: 'Visa Type',
      placeholder: 'Visa Type',
      items: VISA_OPTIONS,
      prependInnerIcon: mdiCardAccountDetails,
      savableProperty: useInsuredStore(module, pinia).visa_type,
    }),
    genBuilderProps('visa_type', additionalProps),
    {
      overrideSave: () => useInsuredStore(module, pinia).saveAttributes(['visa_type']),
      ...additionalProps,
    },
  );
}
export function useInsuredUsEntryDateProperty(pinia, module, additionalProps = {}) {
  return FormFieldAdapter(
    DateField({
      dataTestid: storeKeyPrefix(module, 'us-entry-date'),
      label: 'U.S. Entry Date',
      placeholder: 'U.S. Entry Date',
      prependInnerIcon: mdiCalendar,
      savableProperty: useInsuredStore(module, pinia).us_entry_date,
    }),
    genBuilderProps('us_entry_date', additionalProps),
    {
      overrideSave: () => useInsuredStore(module, pinia).saveAttributes(['us_entry_date']),
      ...additionalProps,
    },
  );
}
export function useInsuredCountryOfCitizenshipProperty(pinia, module, additionalProps = {}) {
  return FormFieldAdapter(
    AutocompleteField({
      dataTestid: storeKeyPrefix(module, 'country-of-citizenship'),
      label: 'Country of Citizenship',
      placeholder: 'Country of Citizenship',
      items: COUNTRIES,
      prependInnerIcon: mdiEarth,
      savableProperty: useInsuredStore(module, pinia).country_of_citizenship,
    }),
    genBuilderProps('country_of_citizenship', additionalProps),
    {
      overrideSave: () => useInsuredStore(module, pinia).saveAttributes(['country_of_citizenship']),
      ...additionalProps,
    },
  );
}
export function useInsuredDriversLicenseHasProperty(pinia, module, additionalProps = {}) {
  return FormFieldAdapter(
    YesNoField({
      dataTestid: storeKeyPrefix(module, 'drivers-license'),
      savableProperty: useInsuredStore(module, pinia).drivers_license_has,
    }),
    genBuilderProps('drivers_license', additionalProps),
    {
      overrideSave: () => useInsuredStore(module, pinia).saveAttributes(['drivers_license_has']),
      ...additionalProps,
    },
  );
}
export function useInsuredDriversLicenseStateProperty(pinia, module, additionalProps = {}) {
  return FormFieldAdapter(
    AutocompleteField({
      dataTestid: storeKeyPrefix(module, 'drivers-license-state'),
      label: "Driver's License State",
      placeholder: "Driver's License State",
      items: STATES,
      prependInnerIcon: mdiHomeGroup,
      savableProperty: useInsuredStore(module, pinia).drivers_license_state,
    }),
    genBuilderProps('drivers_license_state', additionalProps),
    {
      overrideSave: () => useInsuredStore(module, pinia).saveAttributes(['drivers_license_state']),
      ...additionalProps,
    },
  );
}
export function useInsuredDriversLicenseExpirationProperty(pinia, module, additionalProps = {}) {
  return FormFieldAdapter(
    DateField({
      dataTestid: storeKeyPrefix(module, 'drivers-license-expiration'),
      label: "Driver's License Expiration",
      placeholder: "Driver's License Expiration",
      prependInnerIcon: mdiCalendar,
      savableProperty: useInsuredStore(module, pinia).drivers_license_expiration,
    }),
    genBuilderProps('drivers_license_expiration', additionalProps),
    {
      overrideSave: () =>
        useInsuredStore(module, pinia).saveAttributes(['drivers_license_expiration']),
      ...additionalProps,
    },
  );
}
export function useInsuredBeneficiaryAmountProperty(pinia, module, additionalProps = {}) {
  return FormFieldAdapter(
    IntegerField({
      dataTestid: storeKeyPrefix(module, 'beneficiary-amount'),
      label: 'Beneficiary Amount',
      placeholder: 'Beneficiary Amount',
      prependInnerIcon: mdiPercent,
      min: 1,
      max: 100,
      savableProperty: useInsuredStore(module, pinia).beneficiary_amount,
    }),
    genBuilderProps('beneficiary_amount', additionalProps),
    {
      overrideSave: () => useInsuredStore(module, pinia).saveAttributes(['beneficiary_amount']),
      ...additionalProps,
    },
  );
}
export function useInsuredDriversLicenseNumberProperty(pinia, module, additionalProps = {}) {
  return FormFieldAdapter(
    TextField({
      dataTestid: storeKeyPrefix(module, 'drivers-license-number'),
      label: "Driver's License Number",
      placeholder: "Driver's License Number",
      prependInnerIcon: mdiCardAccountDetails,
      savableProperty: useInsuredStore(module, pinia).drivers_license_number,
    }),
    genBuilderProps('drivers_license_number', additionalProps),
    {
      overrideSave: () => useInsuredStore(module, pinia).saveAttributes(['drivers_license_number']),
      ...additionalProps,
    },
  );
}
export function useInsuredDriversLicenselessReasonProperty(pinia, module, additionalProps = {}) {
  return FormFieldAdapter(
    TextField({
      dataTestid: storeKeyPrefix(module, 'drivers-licenseless-reason'),
      label: "No Driver's License Reason",
      placeholder: "No Driver's License Reason",
      prependInnerIcon: mdiMessageReply,
      savableProperty: useInsuredStore(module, pinia).drivers_licenseless_reason,
    }),
    genBuilderProps('drivers_licenseless_reason', additionalProps),
    {
      overrideSave: () =>
        useInsuredStore(module, pinia).saveAttributes(['drivers_licenseless_reason']),
      ...additionalProps,
    },
  );
}
export function useInsuredBankruptcyProperty(pinia, module, additionalProps = {}) {
  return FormFieldAdapter(
    YesNoField({
      dataTestid: storeKeyPrefix(module, 'bankruptcy'),
      savableProperty: useInsuredStore(module, pinia).bankruptcy,
    }),
    genBuilderProps('bankruptcy', additionalProps),
    {
      overrideSave: () => useInsuredStore(module, pinia).saveAttributes(['bankruptcy']),
      ...additionalProps,
    },
  );
}
export function useInsuredBankruptcyTypeProperty(pinia, module, additionalProps = {}) {
  return FormFieldAdapter(
    SelectField({
      dataTestid: storeKeyPrefix(module, 'bankruptcy-type'),
      items: BANKRUPTCY_TYPE_OPTIONS,
      prependInnerIcon: mdiTag,
      label: 'Bankruptcy Type',
      placeholder: 'Bankruptcy Type',
      savableProperty: useInsuredStore(module, pinia).bankruptcy_type,
    }),
    genBuilderProps('bankruptcy_type', additionalProps),
    {
      overrideSave: () => useInsuredStore(module, pinia).saveAttributes(['bankruptcy_type']),
      ...additionalProps,
    },
  );
}
export function useInsuredBankruptcyDischargeDateProperty(pinia, module, additionalProps = {}) {
  return FormFieldAdapter(
    DateField({
      dataTestid: storeKeyPrefix(module, 'bankruptcy-discharge-date'),
      prependInnerIcon: mdiCalendar,
      label: 'Bankruptcy Discharge Date',
      placeholder: 'Bankruptcy Discharge Date',
      savableProperty: useInsuredStore(module, pinia).bankruptcy_discharge_date,
    }),
    genBuilderProps('bankruptcy_discharge_date', additionalProps),
    {
      overrideSave: () =>
        useInsuredStore(module, pinia).saveAttributes(['bankruptcy_discharge_date']),
      ...additionalProps,
    },
  );
}
export function useInsuredSendClientIntroMessageProperty(pinia, module, additionalProps = {}) {
  return FormFieldAdapter(
    YesNoField({
      dataTestid: storeKeyPrefix(module, 'send-client-intro-message'),
      savableProperty: useInsuredStore(module, pinia).send_client_intro_message,
    }),
    genBuilderProps('send_client_intro_message', additionalProps),
    {
      overrideSave: () =>
        useInsuredStore(module, pinia).saveAttributes(['send_client_intro_message']),
      ...additionalProps,
    },
  );
}
export function useInsuredResendClientIntroMessageProperty(pinia, module, additionalProps = {}) {
  return FormFieldAdapter(
    CustomButtonsField({
      dataTestid: storeKeyPrefix(module, 'resend-intro-message'),
      savableProperty: useInsuredStore(module, pinia).send_client_intro_message,
      items: [{ title: 'Resend', value: true, dataTestid: 'resend' }],
      disabled: additionalProps.cannotSendLink,
      label: additionalProps.label,
    }),
    genBuilderProps('resend-action', { mdColSize: 12, ...additionalProps }),
    {
      overrideSave: () =>
        useInsuredStore(module, pinia).saveAttributes(['send_client_intro_message']),
      ...additionalProps,
    },
  );
}
export function useInsuredPhoneMobileProperty(pinia, module, additionalProps = {}) {
  return FormFieldAdapter(
    PhoneField({
      label: 'Phone',
      placeholder: '(123) 456-7890',
      dataTestid: storeKeyPrefix(module, 'phone-mobile'),
      prependInnerIcon: mdiPhone,
      savableProperty: useInsuredStore(module, pinia).phone_mobile,
    }),
    genBuilderProps('phone_mobile', additionalProps),
    {
      overrideSave: () => useInsuredStore(module, pinia).saveAttributes(['phone_mobile']),
      ...additionalProps,
    },
  );
}
export function useInsuredRelationshipProperty(pinia, module, additionalProps = {}) {
  return FormFieldAdapter(
    SelectField({
      dataTestid: storeKeyPrefix(module, 'relationship'),
      label: 'Relationship',
      placeholder: 'Relationship',
      items: INSURED_RELATIONSHIP_OPTIONS,
      savableProperty: useInsuredStore(module, pinia).relationship,
    }),
    genBuilderProps('relationship', additionalProps),
    {
      overrideSave: () => useInsuredStore(module, pinia).saveAttributes(['relationship']),
      ...additionalProps,
    },
  );
}
export function useInsuredRoutingNumberProperty(pinia, module, additionalProps = {}) {
  return FormFieldAdapter(
    RoutingNumberField({
      prependInnerIcon: mdiBank,
      dataTestid: storeKeyPrefix(module, 'routing-number'),
      label: 'Routing Number',
      savableProperty: useInsuredStore(module, pinia).routing_number,
    }),
    genBuilderProps('routing_number', additionalProps),
    {
      overrideSave: () => {},
      ...additionalProps,
    },
  );
}
export function useInsuredAccountNumberProperty(pinia, module, additionalProps = {}) {
  return FormFieldAdapter(
    AccountNumberField({
      prependInnerIcon: mdiBank,
      dataTestid: storeKeyPrefix(module, 'account-number'),
      label: 'Account Number',
      savableProperty: useInsuredStore(module, pinia).account_number,
    }),
    genBuilderProps('account_number', additionalProps),
    {
      overrideSave: () => {},
      ...additionalProps,
    },
  );
}
export function useInsuredEftProperty(pinia, module, additionalProps = {}) {
  return FormFieldAdapter(
    YesNoField({
      dataTestid: storeKeyPrefix(module, 'eft'),
      savableProperty: useInsuredStore(module, pinia).eft,
    }),
    genBuilderProps('eft', additionalProps),
    {
      overrideSave: () => useInsuredStore(module, pinia).saveAttributes(['eft']),
      ...additionalProps,
    },
  );
}
export function useInsuredExamSkipReason(pinia, module, additionalProps = {}) {
  return FormFieldAdapter(
    RadioField({
      dataTestid: storeKeyPrefix(module, 'exam-skip-reason'),
      savableProperty: useInsuredStore(module, pinia).exam_skip_reason,
      items: noExamReasons,
      centered: false,
    }),
    genBuilderProps('exam_skip_reason', additionalProps),
    {
      overrideSave: () => useInsuredStore(module, pinia).saveAttributes(['exam_skip_reason']),
      ...additionalProps,
    },
  );
}

export function useInsuredSyncNow(pinia, module, additionalProps = {}) {
  return FormFieldAdapter(
    YesNoField({
      dataTestid: storeKeyPrefix(module, 'sync-now'),
      savableProperty: useInsuredStore(module, pinia).sync_now,
    }),
    genBuilderProps('sync_now', additionalProps),
    {
      overrideSave: () => useInsuredStore(module, pinia).saveAttributes(['sync_now']),
      ...additionalProps,
    },
  );
}

export function useInsuredDraftDayOfMonth(pinia, module, additionalProps = {}) {
  return FormFieldAdapter(
    IntegerField({
      dataTestid: storeKeyPrefix(module, 'draft-day-of-month'),
      prependInnerIcon: mdiCalendar,
      min: 0,
      max: 28,
      savableProperty: useInsuredStore(module, pinia).draft_day_of_month,
    }),
    genBuilderProps('draft_day_of_month', additionalProps),
    {
      overrideSave: () => useInsuredStore(module, pinia).saveAttributes(['draft_day_of_month']),
      ...additionalProps,
    },
  );
}
